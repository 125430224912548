<template>
  <q-layout @resize="onResize" view="lHh Lpr lFf">
    <q-header elevated>
      <q-toolbar>

        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
        >
          <q-icon name="menu" />
        </q-btn>

        <q-toolbar-title>
          {{$store.state.title}}
          <span slot="subtitle">Drill yourself with verbal flashcards for pronunciation or memorization.</span>
        </q-toolbar-title>

        <div class="text-right" v-html="$store.state.subtitle"></div>
      </q-toolbar>
    </q-header>

     <q-drawer
      v-model="leftDrawerOpen"
      bordered
      content-class="bg-grey-2"
    >
      <q-list>
        <q-item-label header>Essential Links</q-item-label>

        <q-item to="/" >
          <q-item-section avatar>
            <q-icon name="home"></q-icon>
            <q-item-label>Home</q-item-label>
          </q-item-section>
        </q-item>

        <q-item  exact replace to="/grill/create">
          <q-item-section avatar>
            <q-icon name="edit"></q-icon>
            <q-item-label>Setup a confession</q-item-label>
          </q-item-section>
        </q-item>
        </q-list>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { openURL } from 'quasar'

export default {
  name: 'MyLayout',
  data () {
    return {
      leftDrawerOpen: this.$q.platform.is.desktop
    }
  },
  mounted () {
    this.onResize()
  },
  methods: {
    openURL,
    onResize () {
      let mql = window.matchMedia('(orientation: portrait)')
      if (mql.matches) {
        this.$root.landscape = false
        this.$set(this.$root, 'landscape', false)
        console.log('orientation landscape set: ' + this.$root.landscape)
      }
      else {
        this.$root.landscape = true
        this.$set(this.$root, 'landscape', true)
        console.log('orientation landscape set: ' + this.$root.landscape)
      }
/*
      let screen = screen || window
      let orientation = screen.msOrientation || screen.mozOrientation || screen.orientation || {}

      let val = orientation.includes('landscape')
      this.$root.landscape = val
      this.$set(this.$root, 'landscape', val)
      console.log('orientation landscape set: ' + this.$root.landscape) */
    },
  }
}
</script>

<style>
  .hidden {
    display: none;
  }

</style>
